/* eslint-disable import/no-unresolved */
import { graphql, useStaticQuery } from 'gatsby'

// Language
import { getLanguage } from 'services/language'

const blogInfo = {
  get() {
    const language = getLanguage()

    const { 
      blogInfoNL,
      blogInfoEN
     } = useStaticQuery(graphql`
      {
        blogInfoNL: wordpressPage(wordpress_id: {in: [124, 139]}, wpml_current_locale: {eq: "nl_NL"}) {
          ...blogInfoContent
        }
  
        blogInfoEN: wordpressPage(wordpress_id: {in: [124, 139]}, wpml_current_locale: {eq: "en_US"}) {
          ...blogInfoContent
        }
      }
  
      fragment blogInfoContent on wordpress__PAGE {
        path

        acf {
          overview {
            filter_title
            readtime_title
            read_more_title
            load_more_title
            back_button_title
          }
        }
      }
    `)
  
    return language === 'nl_NL' ? blogInfoNL : blogInfoEN
  }
}

export default blogInfo