/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import RatingAlt from 'components/Reviews/RatingAlt'
import NationsSeeker from 'components/NationsSeeker'


// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ImageBackground from 'components/elements/ImageBackground'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import BackgroundDescription from 'components/elements/BackgroundDescription'

// Data
import blogInfo from 'src/graphql/blogInfo'

const Intro = styled(BlockDefault)`
  @media(min-width: 992px) {
    margin-top: -150px;

    & h2 {
      font-size: ${props => props.theme.font.size.xl};
      font-weight: ${props => props.theme.font.weight.s};

      & strong {
        font-weight: ${props => props.theme.font.weight.xl};
      }
    }
  }

  @media(max-width: 991px) {
    margin-top: -100px;
  }
`

const FooterIntro = styled(BlockDefault)`
  @media(min-width: 992px) {
    & h2 {
      font-size: ${props => props.theme.font.size.xl};
      font-weight: ${props => props.theme.font.weight.s};

      & strong {
        font-weight: ${props => props.theme.font.weight.xl};
      }
    }
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      
      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        banner_block {
          title
          button {
            title
            url
          }
          button_info
        }

        search {
          title
          description
          background_description
          highlighted
          button {
              title
              url
          }
        }


        footer_block {
          title
          button {
            title
            url
          }
          button_info
        }
       
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroDefault small image={acf.banner.image}>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            {parse(acf.banner.title)}
          </HeroDescription>
        </HeroDefault>
      </section>

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <Intro bigBorderRadius>
                <div className="px-lg-4 py-lg-2">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                      {parse(acf.banner_block.title)}
                    </div>
                    <div className="col-md-6 text-center mt-4 mt-md-0 d-flex align-items-center justify-content-center justify-content-lg-end">
                      <div>
                        <RatingAlt noTitle className="pb-lg-0 pb-4" />
                        <div className="mt-4">
                          <ButtonDefault to={acf.banner_block.button.url} type="next">
                            {acf.banner_block.button.title}
                          </ButtonDefault>
                          <div className="mt-3">
                            {acf.banner_block.button_info}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Intro>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <BackgroundDescription className="mt-lg-3" content={acf.search.background_description} />
        </div>
      </section>

      <section>
        <div className="container pt-lg-5 pt-0 mb-5 pt-lg-5">
          <NationsSeeker fields={acf.search} showAll hideButton />
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <FooterIntro bigBorderRadius>
                <div className="px-lg-4 py-lg-2">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                      {parse(acf.footer_block.title)}
                    </div>
                    <div className="col-md-6 text-center mt-4 mt-md-0 d-flex align-items-center justify-content-center justify-content-lg-end">
                      <div>
                        <RatingAlt noTitle className="pb-lg-0 pb-4" />
                        <div className="mt-4">
                          <ButtonDefault to={acf.footer_block.button.url} type="next">
                            {acf.footer_block.button.title}
                          </ButtonDefault>
                          <div className="mt-3">
                            {acf.footer_block.button_info}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FooterIntro>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageTemplate
