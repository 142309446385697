import styled from 'styled-components'

const HeroDescription = styled.div`
  color: ${props => props.theme.color.text.light};
  
  & h1, & h2, & h3 {
    text-shadow: 0 0 15px rgba(0, 0, 0, .5);
    font-weight: ${props => props.theme.font.weight.s};

    & strong {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }

  @media (min-width: 992px) {
    & h1 {
      font-size: ${props => props.theme.font.size.xxxl};
    }
  }

  @media (max-width: 991px) {
    & h1 {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }
`

export default HeroDescription